import { tw } from '@/utils/tw';
import CheckboxTheme from 'base/components/Checkbox/theme';

const { Caption } = CheckboxTheme;

const Checkbox = tw.theme({
  extend: CheckboxTheme,
  slots: {
    label: ['text-preview-sm', '-mb-[3px]'],
    input: [
      'active:before:scale-0',
      'active:bg-gray-100',
      'after:-translate-x-1/2',
      'after:-translate-y-2/3',
      'after:absolute',
      'after:block',
      'after:border-b-2',
      'after:border-gray-900',
      'after:border-l-2',
      'after:h-1.25',
      'after:left-1/2',
      'after:origin-center',
      'after:scale-0',
      'after:top-1/2',
      'after:transition-all',
      'after:w-2.5',
      'appearance-none',
      'border-gray-900',
      'border',
      'checked:after:-rotate-45',
      'checked:after:scale-100',
      'checked:focus:shadow-4',
      'checked:focus:shadow-yves-klein-200',
      'checked:hover:shadow-none',
      'focus:before:scale-100',
      'focus:border-yves-klein-700',
      'h-5',
      'hover:border-black',
      'hover:shadow-4',
      'hover:shadow-yves-klein-200',
      'p-1.25',
      'relative',
      'rounded-sm',
      'transition-all',
      'w-5',
    ],
  },
  variants: {
    variant: {
      alternative: {
        input: [
          'active:bg-gray-100',
          'active:outline-0',
          'active:shadow-md',
          'bg-white',
          'border-yves-klein-900',
          'checked:focus:border',
          'checked:focus:outline-4',
          'checked:hover:outline-0',
          'h-9',
          'hover:outline-4',
          'outline-0',
          'outline-yves-klein-200',
          'outline',
          'shadow-[0_1px_4px_rgba(0,0,0,0.25)]',
          'w-9',
        ],
      },
    },
  },
});

export default Object.assign(Checkbox, { Caption });
