/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { withStandaloneInstanceof } from '@/utils/withInstanceofProp';
import { mergeProps } from '@/utils/merge';
import { ErrorBoundary } from 'base/components/ErrorBoundary';
import { StandaloneCheckoutHeader as ResolvedStandaloneCheckoutHeader } from 'base/components/standalone/CheckoutHeader';

export const CheckoutHeader: typeof ResolvedStandaloneCheckoutHeader = withStandaloneInstanceof((props) => {
    return (
        <ErrorBoundary>
            <ResolvedStandaloneCheckoutHeader {...props} />
        </ErrorBoundary>
    );
});

export type CheckoutHeaderProps = PropsFromComponent<typeof CheckoutHeader>;

/** @deprecated Use slot architecture instead */
export const CheckoutHeaderWith = (extras: DynamicStandaloneExtras): typeof CheckoutHeader => {
    return function CheckoutHeader(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'CheckoutHeader');
        return <ResolvedStandaloneCheckoutHeader {...mergeProps({ options: { theme } }, props)} />;
    }
}